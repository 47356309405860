exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-copy-js": () => import("./../../../src/pages/contact-us copy.js" /* webpackChunkName: "component---src-pages-contact-us-copy-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-doctor-speak-js": () => import("./../../../src/pages/doctor-speak.js" /* webpackChunkName: "component---src-pages-doctor-speak-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labs-diagnostic-tests-js": () => import("./../../../src/pages/labs/diagnostic-tests.js" /* webpackChunkName: "component---src-pages-labs-diagnostic-tests-js" */),
  "component---src-pages-labs-packages-js": () => import("./../../../src/pages/labs/packages.js" /* webpackChunkName: "component---src-pages-labs-packages-js" */),
  "component---src-pages-medical-equipment-js": () => import("./../../../src/pages/medical-equipment.js" /* webpackChunkName: "component---src-pages-medical-equipment-js" */),
  "component---src-pages-partner-with-us-js": () => import("./../../../src/pages/partner-with-us.js" /* webpackChunkName: "component---src-pages-partner-with-us-js" */),
  "component---src-pages-patient-stories-js": () => import("./../../../src/pages/patient-stories.js" /* webpackChunkName: "component---src-pages-patient-stories-js" */),
  "component---src-pages-press-media-js": () => import("./../../../src/pages/press-media.js" /* webpackChunkName: "component---src-pages-press-media-js" */),
  "component---src-pages-site-index-js": () => import("./../../../src/pages/site-index.js" /* webpackChunkName: "component---src-pages-site-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-doctor-speak-details-js": () => import("./../../../src/templates/doctor-speak-details.js" /* webpackChunkName: "component---src-templates-doctor-speak-details-js" */),
  "component---src-templates-labpackage-js": () => import("./../../../src/templates/labpackage.js" /* webpackChunkName: "component---src-templates-labpackage-js" */),
  "component---src-templates-labtest-js": () => import("./../../../src/templates/labtest.js" /* webpackChunkName: "component---src-templates-labtest-js" */),
  "component---src-templates-medical-equipment-category-js": () => import("./../../../src/templates/medical-equipment-category.js" /* webpackChunkName: "component---src-templates-medical-equipment-category-js" */),
  "component---src-templates-medical-equipment-js": () => import("./../../../src/templates/medical-equipment.js" /* webpackChunkName: "component---src-templates-medical-equipment-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-patient-story-js": () => import("./../../../src/templates/patient-story.js" /* webpackChunkName: "component---src-templates-patient-story-js" */),
  "component---src-templates-people-js": () => import("./../../../src/templates/people.js" /* webpackChunkName: "component---src-templates-people-js" */),
  "component---src-templates-service-testimonial-js": () => import("./../../../src/templates/service_testimonial.js" /* webpackChunkName: "component---src-templates-service-testimonial-js" */),
  "component---src-templates-vanilla-js": () => import("./../../../src/templates/vanilla.js" /* webpackChunkName: "component---src-templates-vanilla-js" */)
}

